import { T } from "@/locales";

export default [
  {
    key: "report",
    name: T("报表"),
    icon: "bar-chart",
    submenus: [
      { key: "/inventory_report", name: T("库存明细") },
      { key: "/material_inventory_report", name: T("库存报表") },
      { key: "/stock_in_report", name: T("入库报表") },
      { key: "/stock_out_report", name: T("出库报表") },
      { key: "/delivery_record_report", name: T("复核报表") },
      { key: "/location_flow", name: T("库位流水") },
      { key: "/inventory_history", name: T("历史库存") },
      { key: "/warehouse_layout", name: T("库位平面图") },
    ],
  },
  {
    key: "data",
    name: T("基础数据"),
    icon: "table",
    submenus: [
      { key: "/warehouse", name: T("仓库管理") },
      { key: "/reservoir_area", name: T("库区管理") },
      { key: "/stock_location", name: T("库位管理") },
      { key: "/client", name: T("货主管理") },
      { key: "/counterparty", name: T("往来单位") },
      { key: "/order_type", name: T("订单类型") },
    ],
  },
  {
    key: "product",
    name: T("货品管理"),
    icon: "appstore",
    submenus: [
      { key: "/categories", name: T("货品分类") },
      { key: "/unit", name: T("单位管理") },
      { key: "/material", name: T("货品信息") },
      { key: "/expiration_warning", name: T("临期预警") },
      { key: "/inventory_warning", name: T("库存预警") },
    ],
  },
  {
    key: "stock_in",
    name: T("入库管理"),
    icon: "import",
    submenus: [
      { key: "/stock_in/receipt_order_list", name: T("入库通知单") },
      { key: "/stock_in/receipt_task", name: T("收货任务") },
      { key: "/stock_in/shelve_task", name: T("上架任务") },
    ],
  },
  {
    key: "stock_out",
    name: T("出库管理"),
    icon: "export",
    submenus: [
      { key: "/stock_out/delivery_order_list", name: T("出库通知单") },
      { key: "/stock_out/picking_task", name: T("拣货任务") },
      { key: "/stock_out/delivery_task", name: T("复核任务") },
    ],
  },
  {
    key: "stock_manage",
    name: T("库存管理"),
    icon: "database",
    submenus: [
      { key: "/stock_check/stock_check_task", name: T("盘点单") },
      { key: "/stock_check/profit_loss_order_list", name: T("盘点损益单") },
      { key: "/stock_transfer/order_record", name: T("调拨单") },
      { key: "/location_transfer_order_list", name: T("移库单") },
      { key: "/quality_adjust_order_list", name: T("品质调整单") },
    ],
  },
  {
    key: "charge",
    name: T("计费管理"),
    icon: "account-book",
    submenus: [
      { key: "/charge/rule", name: T("计费规则") },
      { key: "/charge/expense", name: T("其他费用") },
      { key: "/charge/report", name: T("费用合计") },
    ],
  },
  {
    key: "system",
    name: T("系统管理"),
    icon: "team",
    submenus: [
      { key: "/role", name: T("角色管理") },
      { key: "/account", name: T("员工账号") },
      { key: "/order_prefix", name: T("单据字头") },
      { key: "/label_configs", name: T("标签配置") },
      { key: "/print_configs", name: T("打印配置") },
      { key: "/system_configs", name: T("系统配置") },
      { key: "/logo_configs", name: T("Logo配置") },
      { key: "/operation_log", name: T("操作日志") },
      { key: "/login_log", name: T("登录日志") },
      { key: "/update_log", name: T("更新日志") },
      { key: "/mobile_download", name: T("移动端下载") },
    ],
  },
];
